import React from 'react';
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import bgimage from "../../images/login-img/login-image.png";
import { useState } from "react";
import { IoIosEyeOff, IoMdEye } from "react-icons/io";
import axios from "axios";
import { BaseURL } from "../../Helper/config";
import { useDispatch } from "react-redux";
import {
  setToken,
  setUser,
} from "../../Redux/feature/Authentication/AuthenticationSlice";
import { toast } from "react-toastify";

const Login = () => {
  const [userType, setUserType] = useState('seller');
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const onLogin = async (e) => {
    e.preventDefault();
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);

    const loginData = isMobile
      ? { email: email, userPassword: password, userType: userType }
      : { email: email, userPassword: password };

    const data1 = await axios.post(`${BaseURL}/login-users`, loginData);

    console.log(data1.data);

    const { data, token } = data1.data;

    dispatch(setUser(data));
    dispatch(setToken(token));

    // Store user and token in local storage
    localStorage.setItem("UserDetails", JSON.stringify(data));
    localStorage.setItem("Token", token);
    if (data1.data.status === "Success") {
      console.log(1);
      navigate("/");
      // toast.success("Successfully Login");
    } else {
      toast.warning(data1.data.message);
    }
  };

  return (
    <div className='container mt-5'>
      <div className='row  align-items-center justify-content-center bg-login'>
        <div className='col-xl-12'>
          <div className='card border-0 shadow-lg p-4'>
            <div className='card-body login-bx'>
              <div className='row mt-5'>
                <div className='col-xl-8 col-md-6 text-center'>
                  <img
                    src={bgimage}
                    className='food-img'
                    alt='Log into your account'
                  />
                </div>
                <div className='col-xl-4 col-md-6 pe-0'>
                  <div className='sign-in-your'>
                    <div className='text-center mb-3'>
                      <img src={logo} className='mb-3' alt='' />
                      <h4 className='fs-20 font-w800 text-black'>Welcome!</h4>
                      <span className='font-w800'>Log Into Your Account</span>
                    </div>
                    <form onSubmit={onLogin}>
                      <div className='mb-3'>
                        <label className='mb-1'>
                          <strong>Email Address/Phone Number</strong>
                        </label>
                        <input
                          type='text'
                          className='form-control shadow-sm'
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder='Enter email or phone number'
                        />
                      </div>
                      <div className='mb-3 password'>
                        <label className='mb-1'>
                          <strong>Password</strong>
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          className='form-control shadow-sm'
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder='Enter password'
                        />
                        <div className='eye-container'>
                          <span
                            className='eye'
                            onClick={() => handleTogglePassword("password")}
                          >
                            {showPassword ? (
                              <IoMdEye size={20} color='#0d6efd' />
                            ) : (
                              <IoIosEyeOff size={20} color='#0d6efd' />
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="mb-4 d-md-none d-flex align-items-center">
                        <label className="mb-2 d-block me-2"><strong>Login as</strong></label>
                        <div className="d-flex gap-3">
                          <div className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="sellerType"
                              name="userType"
                              value="seller"
                              checked={userType === 'seller'}
                              onChange={(e) => setUserType(e.target.value)}
                            />
                            <label className="form-check-label" htmlFor="sellerType">
                              Seller
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="riderType"
                              name="userType"
                              value="rider"
                              checked={userType === 'rider'}
                              onChange={(e) => setUserType(e.target.value)}
                            />
                            <label className="form-check-label" htmlFor="riderType">
                              Rider
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='row d-flex justify-content-between align-items-center mt-4 mb-2'>
                        <div className='mb-3'>
                          <div className='form-check custom-checkbox ms-1 d-flex justify-content-between'>
                            <div>
                              <input
                                type='checkbox'
                                className='form-check-input'
                                id='basic_checkbox_1'
                              />
                              <label
                                className='form-check-label'
                                htmlFor='basic_checkbox_1'
                              >
                                Remember my preference
                              </label>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className='text-center'>
                        <button
                          type='submit'
                          className='btn btn-primary btn-block shadow-sm'
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                    <div className='forget-password-container text-center mt-4 btn btn-outline-primary btn-block'>
                      <Link to={"/forget-password"} className="text-primary">
                        Forget Password
                      </Link>
                    </div>
                    <div className='text-center mt-5'>
                      <span>
                        Don't have an account{" "}
                        <a
                          href='https://page.ownfood.com.bd/CustomerRegistration'
                          className='text-primary'
                        >
                          Create Account
                        </a>
                      </span>
                    </div>


                    <div className="text-center mt-4">
                      <a href="https://ownfood.com.bd/" className="btn btn-outline-secondary btn-block d-none d-md-inline-block">
                        Back to Home
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
